.reserve {
    color: #fff;
    // background-image: url("../images/slideBottom.png");
    width: 100%;
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &__content {
        margin-left: 150px;
        padding-top: 100px;
        height: 400px;

        h2 {
            font-size: 30px;
        }

        p {
            font-size: 26px;
            width: 50%;
            margin: 25px 0px 30px 0px;
        }

        a {
            padding: 15px 0px;
            border: 1px solid #fff;
            transition: all 0.3s;
            border-radius: 30px;
            display: block;
            text-align: center;
            width: 180px;

            &:hover {
                color: #fff;
                border-color: $primary-color;
                background-color: $primary-color;
            }
        }
    }
}

@media (max-width: 1250px) {
    .reserve {
        height: auto;
        min-height: 400px;
        padding-bottom: 25px;

        &__content {
            margin-left: 25px;
            padding-top: 50px;
            height: auto;
            min-height: 400px;

            p {
                width: 90%;
            }
        }
    }
}

@media (max-width: 1000px) { 
    .reservation-button {
        // position: relative;
        // top: 0px;
        // width: 100%;
        margin-top: 15px;
        position: absolute;
        top: 145px;
        width: 220px;
        background-size: 40px;
        height: 60px;

        right: -160px;

        &.is-showed {
            right: 0px;
        }
        
        span {
            margin-left: 50px;
        }
    }

    body {
        overflow-x: hidden;
    }
}

