.main-header {
    font-size: 36px;
    text-align: center;
    margin-bottom: 20px;
}

.main-paragraf {
    width: 70%;
    margin: 0 auto;
    text-align: center;
}

.selector-menu {
    height: 101px;
}

.header-main-name {
    margin-left: 240px;
    font-size: 36px;
    text-transform: uppercase;
    // font-family: $header-font-family;
}

@media (max-width: 768px) {
    .main-paragraf {
        width: 100%;
    }

    .main-header {
        font-size: 32px;
        margin-bottom: 30px;
    }
}

.our-logo {
    width: 90px;
    height: 90px;
    margin: 0 auto 15px auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../images/pies.svg);
}


.short-desc {
    width: 50%;
    margin: 0 auto 50px auto;
    text-align: center;
    font-size: 18px;
}


@media (max-width: 1200px) {
    .short-desc{
        width: 70%;
    }
}

@media (max-width: 768px) {
    .short-desc{
        width: 100%;
        padding: 0 15px;
    }
}