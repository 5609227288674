// If you have any variables in your code, place it here


//Colors

// $primary-color: #e8bf67;
$primary-color: #EB2F2D;
$secondary-color: blue;
$alert-color: red;
$success-color: green;

// Box shadows 

$small-box-shadow : 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
$medium-box-shadow : 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);


@font-face {
    font-family: Lato-Regular;
    src: url(../fonts/Lato-Regular.ttf);
}

@font-face {
    font-family: KaushanScript-Regular;
    src: url(../fonts/KaushanScript-Regular.ttf);
}

$main-font-family: 'Lato-Regular', sans-serif;
$header-font-family: 'KaushanScript-Regular', sans-serif;


// inputs 

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
textarea:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
textarea:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */
