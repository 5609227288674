.offert-box {
    width: 70%;
    margin: 0 auto;
    padding: 15px;
    border-bottom: 1px solid $primary-color;

    &__header {
        display: flex;
        align-items: center;
        
        h2 {
            font-size: 26px;
            margin-right: 20px;
            font-weight: bold;
            margin-bottom: 5px;
        }
        span {
            font-size: 21px;
            color: $primary-color;
        }
    }

    &__button-more {
        color: $primary-color;
        cursor: pointer;
        margin-bottom: 15px;
        margin-top: 15px;
        font-weight: bold;
    }

    &__short-about {
        margin: 20px 0px;
        font-size: 18px;
    }

    &__about {
        margin: 20px 0px;
        font-size: 16px;
        display: none;

        h3 {
            font-weight: bold;
            margin: 20px 0px;
        }

        ul {
            li {
                margin: 10px 0px;
                padding-left: 30px;
                position: relative;
                
                &:before {
                    content: '';
                    position: absolute;
                    left: 0px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    background-image: url(../images/pies.svg);
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    &__img {
        width: 70%;
        margin: 0 auto;
        height: 300px;
        // background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: all 0.3s;

        @media(max-width: 1100px) {
            width: 100%;
        }

        &.is-active {
            height: 45vw;
        }
        
        img {
            width: 100%;
        }


    }

    &__about--price {
        width: 50%;
        h3 {
            margin: 20px 0px;
            font-weight: bold;
        }

        .price-more {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #000;

            .price-category {
                padding-left: 25px;
            }

            &__right{ 
                display: flex;
                justify-content: space-between;
                width: 150px;

                .price {
                    font-weight: bold;
                    color: $primary-color;
                }
            }
        }
    }
}

.message-read-more {
    position: fixed;
    display: none;
    width: 80%;
    height: 650px;
    padding: 45px;
    z-index: 99999999999999;
    background-color: #fff;
    top: 50%;
    left: 50%;
    margin-left: -40%;
    margin-top: -275px;
    overflow: inherit;
    // overflow-y: scroll;
    // margin-top: -100px; /* Negative half of height. */
    // margin-left: -250px; /* Negative half of width. */
    -webkit-box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.75);

    .offert-box__img {
        height: 960px;
    }

  
}

.close-read-more {
    height: 50px;
    width: 50px;
    background-color: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.75);
    position: relative;
    position: absolute;
    right: -20px;
    top: -20px;
    cursor: pointer;
    overflow: initial;

    &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 3px;
        right: 0;
        content: "\274c"; /* use the hex value here... */
        font-size: 30px; 
        color: $primary-color;
        line-height: 49px;
        text-align: center;
    }
}

.message-read-container {
    overflow-y: scroll;
    height: 100%;
    padding-right: 15px;
}

@media (max-width: 768px) {

    .message-read-more {
        top: 50%;
        padding: 45px 20px;
    }

    .message-read-container {
        
        .offert-box__img {
            height: 40vw;
        }

        .offert-box__about--price {
            width: 100%;
        }
        .offert-box__header {
            flex-direction: column;

            span {
                margin-top: 15px;
            }
        }
    }
    .offert-box {
        width: 100%;
    }
    .offert-box__about--price {
        width: 100%;
    }
}