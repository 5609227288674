.read {
 
    padding-top: 30px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__container {
        margin-top: 50px;
        display: flex;
        justify-content: space-around;
        width: 80%;
        margin: 0 auto;

        &--solo {
            display: flex;
            flex-direction: column;
  
            align-items: center;

            width: 600px;
        }
    }
}

.read-solo {
    &__photo {
        width: 600px;
        height: 400px;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__header {
        font-size: 30px;
        margin: 25px 0px;
    }

    &__text {
        font-size: 21px;
        
        p {
            margin-bottom: 25px;
        }
    }
}

.read-more-articles {
    border: 1px solid $primary-color;
    border-radius: 30px;

    padding: 15px 25px;
    margin: 50px auto 0px auto;
    transition: all 0.3s;

    &:hover {
        background-color: $primary-color;
        color: #fff;
    }
}

@media(max-width: 1400px) {
    .read {

        &__container {
            width: 100%;
        }
    }
    
}

@media(max-width: 1250px) {
    .read {

        &__container {
            flex-direction: column;
            align-items: center;

            &--solo {
                margin-bottom: 25px;
            }
        }

    }

    .read-solo {

        &__photo {
            width: 100%;
            height: 60vw;
        }

        &__text {
            font-size: 18px;
        }
    }
    .read-more-articles {
        margin-top: 30px;
    }   
}

@media(max-width: 768px) {
    .read {
        &__container {

            &--solo {
                width: 100%;
                padding: 0 15px;
            }
        }
    }
}