.baner-top {
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center; 
    height: 500px;
}

.contact-container {
    display: flex;
    justify-content: space-around;
    width: 900px;
    margin: 0 auto;


    &__right {
        width: 400px;
        display: flex;
        flex-direction: column;
        font-size: 21px;
        padding-left: 30px;

        h2 {
            font-size: 26px;
            font-weight: bold;
        }

        p {
            margin: 15px 0px;
         
        }
    }
    span {
        font-weight: bold;
    }
}

.to-contact {
    margin: 5px 0px;
    background-repeat: no-repeat;
    background-position: left;
    padding-left: 30px;
    background-size: contain;
}

.reserve-contact {
    width: 200px;
    padding: 12px 0px;
    background-color: $primary-color;
    border-radius: 25px;
    color: #fff;
    text-align: center;
    transition: all 0.3s;
    font-size: 18px;

    &:hover {
        background-color: #d1100e;

    }
}

.form-contact {
    font-family: $main-font-family;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 400px;
    // margin: 0 auto;

    input {
        margin: 10px 0px;
        height: 50px;
        border-radius: 30px;
        border: 1px solid $primary-color;
        transition: all 0.3s;
        padding: 5px 15px;
        width: 100%;

        &:focus {
            box-shadow: $small-box-shadow;
            outline: none;
        }
    }

    textarea {
        @extend input;
        height: 300px;
        padding: 15px;
        font-family: $main-font-family;
    }
}

.map {
    width: 100%;
    height: 540px;
    margin-top: 20px;
}

button {
   
    border: 1px solid #eb2f2d;
    transition: all .3s;
    border-radius: 30px;
    background-color: #fff;
    width: 125px;
    height: 40px;
    outline: none;
    cursor: pointer;
    margin-left: 275px;

    &:hover {
        background-color: #d1100e;
        color: #fff;
    }
}

.contact-social {
    display: flex;
    margin-bottom: 15px;
    a {
        display: block;
        width: 30px;
        height: 30px;
        margin-right: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transition: all 0.3s;

        &:hover {
            transform: scale(1.2);
        }
    }
}

@media (max-width: 1100px) {
    .contact-container {
        width: 100%;
        flex-direction: column-reverse;
        // align-items: center;

        &__right {
            margin-bottom: 25px;
            padding-left: 0px;
        }
    }
    .baner-top {
        height: 40vw;
    }
}

@media (max-width: 768px) {
    .form-contact {
        width: 100%;
    }
    .contact-container {
        &__right {
            width: 100%;
            // align-items: center;
        }

        &__left {
            width: 100%;
        }
    }
}