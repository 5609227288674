.single-blog {
    h2 {

        span {
            color: $primary-color;
        }
    }
}

.single-blog-container {
    
    img {
        margin: 25px 0px;
        // width: 100%;
        width: auto;
        height: 100%;
    }

    ul {
        line-height: 1.5;
        margin-left: 30px;
        li {
            list-style: disc;
            // margin-bottom: 5px;
        }
    }
}

.other-articles {
    align-items: flex-start;
    margin-bottom: 15px;

    h3 {
        font-size: 23px;
        font-weight: bold;
        margin: 35px 0px 20px 0px;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__single {
        width: 350px;
        &--photo {
            width: 350px;
            height: 200px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        &--name {
            background-color: #f4f2f2;
            height: 70px;
            display: flex;
            align-items: center; 
            width: 350px;

            h2 {
                padding-left: 15px;
            }
        }

    }
}

@media (max-width: 768px) {
    .other-articles__list {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &--name {
            width: 350px;
        }
    }

    .other-articles__single {
        margin-bottom: 30px;
    }
}