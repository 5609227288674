.about-us {
    margin-top: 50px;
}

@media (max-width: 768px) {
    .about-us {
        padding: 0 15px;
        margin-top: 30px;
    }
}

.short-desc-new {
    text-align: left !important;
    width: 70% !important; 
}

@media (max-width: 1100px) {
    .short-desc-new {
        width: auto !important; 
    }
}
