.workers {
    padding: 30px 0px;
    background-color: #f3f3f3;
    margin-top: 30px;

    .swiper-slide {
        background: #f3f3f3;

        .swiper-slide-workers {
            background: #f3f3f3;

            &__photo {
                background-color: #fff;
            }
        }
    }
}

.swiper-workers {
    margin-top: 50px;
    width: 80%;
    padding-bottom: 40px;
}

.swiper-slide-workers {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;

    &__photo {
        width: 270px;
        height: 270px;
        border-radius: 250px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin: 10px 0px;
        border-radius: 250px;
        -webkit-box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.75);
    }

    &__header {
        margin: 15px 0px;
        font-size: 26px;
    }

    &__icon {
        width: 50px;
        height: 20px;
        background-image: url(../images/pies.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__text {
        margin: 15px 0px;
        padding: 0px 20px;

        p {
            margin-bottom: 20px;
        }

        a {
            padding: 5px 10px;
            border: 1px solid $primary-color;
            transition: all 0.3s;
            border-radius: 15px;

            &:hover {
                color: #fff;
                background-color: $primary-color;
            }
        }
    }
    
}

@media (max-width: 1100px) {
    .swiper-workers {
        width: 90%;
    }
}