.swiper-container {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-main {
      padding-top: 101px;
      height: 40vw;


      .swiper-slide {
          background-repeat: no-repeat;
          background-size: cover;   
          position: relative;
          background-position: center;
    
      }
  }

.content-slide {
    color: #fff;
    position: absolute;
    top: 100px;
    left: 15%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h2 {
        font-size: 30px;
        margin-bottom: 10px;
        font-weight: bold;
    }

    p {
        margin-bottom: 30px;
        text-align: left;
        width: 80%;
    }

    a {
        border: 1px solid #fff;
        border-radius: 30px;
        width: 150px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;


        &:hover {
            border-color: $primary-color;
            background-color: $primary-color;
        }
    }

    @media(max-width: 1100px){
        margin-top: 140px;
    }
}

.swiper-pagination-bullet {
    height: 15px;
    width: 15px;
    background-color: $primary-color;
}

.swiper-pagination-bullet-active {
    background-color: $primary-color;
}

.swiper-button-prev {
    background-image: url(../images/next.svg);
    transform: rotate(180deg);
}

.swiper-button-next {
    background-image: url(../images/next.svg);
}

@media (max-width: 900px) {
  .content-slide {
      top: 10px;
  }
  .swiper-button-next, .swiper-button-prev {
      top: 40%;
  }
}

@media (max-width: 768px) {
    .swiper-main {
    //    height: 400px;
    height: 465px;
    }
    .content-slide {
        left: 15px;
    }
  }

  // swiper about us 

  .swiper-about-us {
      height: 250px;
      width: 480px;
      margin-top: 20px;

      .swiper-slide {

        img {
            // width: 600px;
            height: 250px;
        }
      }
  }

  @media (max-width: 768px) {
    .swiper-about-us {
        width: 300px;
        height: 160px;
        .swiper-slide {
            img {
                height: 140px;
            }
        }
    }
}