.blog-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1300px;
    margin: 0 auto;
}

.blog-box {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 400px;
    margin: 25px 0px;

    .date {
        color: #dedfe0;
    }

    h2 {
        margin: 25px 0px;
        font-size: 24px;
        font-weight: bold;
    }
    
    .blod-short-desc {
        font-size: 21px;
        color: #000;
    }

    a {
        padding: 5px 10px;
        border: 1px solid $primary-color;
        transition: all 0.3s;
        border-radius: 15px;
        position: absolute;
        bottom: 5px;
        left: 0px;

        &:hover {
            color: #fff;
            background-color: $primary-color;
        }
    }

    &__left {
        width: 600px;
        position: relative;
    }

    &__right {
        width: 600px;


        img {
            width: 100%;
            height: 100%;
        }
    }

    &:nth-child(2n+2) {
        flex-direction: row-reverse; 
    }
}

@media (max-width: 1200px){
    .blog-container {
        width: 100%;
    }
    .blog-box {
        flex-direction: column;
        align-items: center;
        height: auto;

        a {
            position: relative;
            bottom: auto;
            top: auto;
        }

        &__right {
            margin-top: 15px;
        }

        &:nth-child(2){
            flex-direction: column;
        }
    }
    .blog-short-desc {
        margin-bottom: 15px;
    }
}

@media (max-width: 1200px){ 
    .blog-box {
        
        &__left {
            width: 100%;
        }

        &__right {
            width: 100%;
        }
    }
}