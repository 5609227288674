@font-face {
  font-family: Lato-Regular;
  src: url(../fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: KaushanScript-Regular;
  src: url(../fonts/KaushanScript-Regular.ttf);
}

input:focus::-webkit-input-placeholder, .form-contact textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder, .form-contact textarea:focus:-moz-placeholder {
  color: transparent;
}

/* FF 4-18 */
input:focus::-moz-placeholder, .form-contact textarea:focus::-moz-placeholder {
  color: transparent;
}

/* FF 19+ */
input:focus:-ms-input-placeholder, .form-contact textarea:focus:-ms-input-placeholder {
  color: transparent;
}

/* IE 10+ */
textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

/* FF 4-18 */
textarea:focus::-moz-placeholder {
  color: transparent;
}

/* FF 19+ */
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

/* IE 10+ */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
  font-family: "Lato-Regular", sans-serif;
  font-size: 16px;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  line-height: 1.5;
}

textarea {
  resize: none;
}

* {
  box-sizing: border-box;
}

.message-read-container::-webkit-scrollbar {
  width: 1em;
}

.message-read-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.message-read-container::-webkit-scrollbar-thumb {
  background-color: #EB2F2D;
  outline: 1px solid slategrey;
}

.list::-webkit-scrollbar {
  width: 1em;
}

.list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.list::-webkit-scrollbar-thumb {
  background-color: #EB2F2D;
  outline: 1px solid slategrey;
}

.main-header {
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
}

.main-paragraf {
  width: 70%;
  margin: 0 auto;
  text-align: center;
}

.selector-menu {
  height: 101px;
}

.header-main-name {
  margin-left: 240px;
  font-size: 36px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .main-paragraf {
    width: 100%;
  }
  .main-header {
    font-size: 32px;
    margin-bottom: 30px;
  }
}

.our-logo {
  width: 90px;
  height: 90px;
  margin: 0 auto 15px auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(../images/pies.svg);
}

.short-desc {
  width: 50%;
  margin: 0 auto 50px auto;
  text-align: center;
  font-size: 18px;
}

@media (max-width: 1200px) {
  .short-desc {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .short-desc {
    width: 100%;
    padding: 0 15px;
  }
}

.header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s;
  width: 100%;
  position: fixed;
  z-index: 999;
  background-color: #fff;
  top: 0px;
}

.header-menu-active {
  height: 80px;
}

.main-nav {
  margin-right: 45px;
}

.main-nav ul {
  display: flex;
}

.main-nav ul li {
  padding: 15px 10px;
}

.main-nav ul li a {
  transition: all 0.3s;
}

.main-nav ul li a:hover {
  color: #EB2F2D;
}

.main-nav.is-active {
  top: 80px;
}

.active-link {
  color: #EB2F2D;
  border-bottom: 1px solid #EB2F2D;
}

@media (max-width: 1000px) {
  .main-nav a {
    font-size: 12px;
  }
  .main-nav ul {
    padding-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .main-nav {
    display: none;
    position: absolute;
    height: auto;
    width: 100%;
    top: 100px;
    border-bottom: 1px solid #000;
    background-color: #fff;
  }
  .main-nav ul {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .main-nav ul li {
    height: 34px;
  }
  .main-nav ul li a {
    font-size: 14px;
  }
}

.logo {
  width: 250px;
  height: 177px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: 20px;
}

@media (max-width: 1350px) {
  .logo {
    margin-left: 15px;
  }
}

.fast-contact {
  display: flex;
  margin-left: 15px;
}

.fast-contact__call {
  display: flex;
  margin-right: 15px;
}

.fast-contact__call--logo {
  width: 40px;
  height: 40px;
  background-size: contain;
  background-position: center;
  margin: 0px 10px;
}

.fast-contact__call--text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1350px) {
  .fast-contact {
    display: none;
  }
}

.reservation-button {
  position: absolute;
  top: 100px;
  right: 0px;
  width: 210px;
  height: 70px;
  background-color: #EB2F2D;
  color: #fff;
  border: 1px solid #EB2F2D;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: 20%;
  padding-left: 55px;
  background-image: url(../images/calendar.svg);
}

.reservation-button:hover {
  background-color: #d1100e;
  color: #fff;
}

.reservation-button.is-active {
  top: 300px;
}

.reservation-button-active {
  top: 80px;
}

.reservation-button-mobile {
  top: 310px !important;
}

.language {
  position: absolute;
  top: 32%;
  right: 15px;
  padding-top: 5px;
}

.language:hover .language__en {
  display: block !important;
}

.language:hover .language__pl {
  display: block !important;
}

.language__pl {
  width: 30px;
  height: 20px;
  margin-bottom: 5px;
  display: block;
  background-image: url(../images/pl.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.language__en {
  width: 30px;
  height: 20px;
  display: block;
  background-image: url(../images/en.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.language.is-active {
  top: 29%;
}

.reservation-mobile {
  display: none;
}

.reservation-desktop {
  display: flex;
}

@media (max-width: 900px) {
  .logo {
    width: 80px;
    height: 70px;
    top: 0px;
    position: relative;
    margin-left: 0px;
  }
  .header-main-name {
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .language {
    right: 80px;
  }
  .reservation-button {
    background-size: 50px;
    padding-left: 0px;
  }
  .reservation-button span {
    font-size: 16px;
  }
}

.container-hamburger {
  display: inline-block;
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.container-hamburger {
  display: none;
}

@media (max-width: 768px) {
  .container-hamburger {
    display: block;
    margin-right: 15px;
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-main {
  padding-top: 101px;
  height: 40vw;
}

.swiper-main .swiper-slide {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}

.content-slide {
  color: #fff;
  position: absolute;
  top: 100px;
  left: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.content-slide h2 {
  font-size: 30px;
  margin-bottom: 10px;
  font-weight: bold;
}

.content-slide p {
  margin-bottom: 30px;
  text-align: left;
  width: 80%;
}

.content-slide a {
  border: 1px solid #fff;
  border-radius: 30px;
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.content-slide a:hover {
  border-color: #EB2F2D;
  background-color: #EB2F2D;
}

@media (max-width: 1100px) {
  .content-slide {
    margin-top: 140px;
  }
}

.swiper-pagination-bullet {
  height: 15px;
  width: 15px;
  background-color: #EB2F2D;
}

.swiper-pagination-bullet-active {
  background-color: #EB2F2D;
}

.swiper-button-prev {
  background-image: url(../images/next.svg);
  transform: rotate(180deg);
}

.swiper-button-next {
  background-image: url(../images/next.svg);
}

@media (max-width: 900px) {
  .content-slide {
    top: 10px;
  }
  .swiper-button-next, .swiper-button-prev {
    top: 40%;
  }
}

@media (max-width: 768px) {
  .swiper-main {
    height: 465px;
  }
  .content-slide {
    left: 15px;
  }
}

.swiper-about-us {
  height: 250px;
  width: 480px;
  margin-top: 20px;
}

.swiper-about-us .swiper-slide img {
  height: 250px;
}

@media (max-width: 768px) {
  .swiper-about-us {
    width: 300px;
    height: 160px;
  }
  .swiper-about-us .swiper-slide img {
    height: 140px;
  }
}

.about-us {
  margin-top: 50px;
}

@media (max-width: 768px) {
  .about-us {
    padding: 0 15px;
    margin-top: 30px;
  }
}

.short-desc-new {
  text-align: left !important;
  width: 70% !important;
}

@media (max-width: 1100px) {
  .short-desc-new {
    width: auto !important;
  }
}

.offer {
  margin-top: 50px;
  padding: 30px 0px;
  background-color: #f3f3f3;
}

.swiper-offert {
  margin-top: 50px;
  padding-bottom: 40px;
}

.swiper-slide-offert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;
}

.swiper-slide-offert__photo {
  width: 270px;
  height: 270px;
  margin: 10px 0px;
  border-radius: 250px;
  -webkit-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.75);
  background-size: cover;
  background-repeat: no-repeat;
}

.swiper-slide-offert__header {
  margin: 15px 0px;
  font-size: 26px;
}

.swiper-slide-offert__icon {
  width: 50px;
  height: 20px;
  background-image: url(../images/pies.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.swiper-slide-offert__text {
  margin: 15px 0px;
  padding: 0px 20px;
}

.swiper-slide-offert__text p {
  margin-bottom: 20px;
  min-height: 80px;
}

.swiper-slide-offert__text a {
  padding: 5px 10px;
  border: 1px solid #EB2F2D;
  transition: all 0.3s;
  border-radius: 15px;
}

.swiper-slide-offert__text a:hover {
  color: #fff;
  background-color: #EB2F2D;
}

.offert-container {
  margin-bottom: 25px;
}

.workers {
  padding: 30px 0px;
  background-color: #f3f3f3;
  margin-top: 30px;
}

.workers .swiper-slide {
  background: #f3f3f3;
}

.workers .swiper-slide .swiper-slide-workers {
  background: #f3f3f3;
}

.workers .swiper-slide .swiper-slide-workers__photo {
  background-color: #fff;
}

.swiper-workers {
  margin-top: 50px;
  width: 80%;
  padding-bottom: 40px;
}

.swiper-slide-workers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.swiper-slide-workers__photo {
  width: 270px;
  height: 270px;
  border-radius: 250px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 10px 0px;
  border-radius: 250px;
  -webkit-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.75);
}

.swiper-slide-workers__header {
  margin: 15px 0px;
  font-size: 26px;
}

.swiper-slide-workers__icon {
  width: 50px;
  height: 20px;
  background-image: url(../images/pies.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.swiper-slide-workers__text {
  margin: 15px 0px;
  padding: 0px 20px;
}

.swiper-slide-workers__text p {
  margin-bottom: 20px;
}

.swiper-slide-workers__text a {
  padding: 5px 10px;
  border: 1px solid #EB2F2D;
  transition: all 0.3s;
  border-radius: 15px;
}

.swiper-slide-workers__text a:hover {
  color: #fff;
  background-color: #EB2F2D;
}

@media (max-width: 1100px) {
  .swiper-workers {
    width: 90%;
  }
}

.read {
  padding-top: 30px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.read__container {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;
}

.read__container--solo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
}

.read-solo__photo {
  width: 600px;
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
}

.read-solo__header {
  font-size: 30px;
  margin: 25px 0px;
}

.read-solo__text {
  font-size: 21px;
}

.read-solo__text p {
  margin-bottom: 25px;
}

.read-more-articles {
  border: 1px solid #EB2F2D;
  border-radius: 30px;
  padding: 15px 25px;
  margin: 50px auto 0px auto;
  transition: all 0.3s;
}

.read-more-articles:hover {
  background-color: #EB2F2D;
  color: #fff;
}

@media (max-width: 1400px) {
  .read__container {
    width: 100%;
  }
}

@media (max-width: 1250px) {
  .read__container {
    flex-direction: column;
    align-items: center;
  }
  .read__container--solo {
    margin-bottom: 25px;
  }
  .read-solo__photo {
    width: 100%;
    height: 60vw;
  }
  .read-solo__text {
    font-size: 18px;
  }
  .read-more-articles {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .read__container--solo {
    width: 100%;
    padding: 0 15px;
  }
}

.reserve {
  color: #fff;
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.reserve__content {
  margin-left: 150px;
  padding-top: 100px;
  height: 400px;
}

.reserve__content h2 {
  font-size: 30px;
}

.reserve__content p {
  font-size: 26px;
  width: 50%;
  margin: 25px 0px 30px 0px;
}

.reserve__content a {
  padding: 15px 0px;
  border: 1px solid #fff;
  transition: all 0.3s;
  border-radius: 30px;
  display: block;
  text-align: center;
  width: 180px;
}

.reserve__content a:hover {
  color: #fff;
  border-color: #EB2F2D;
  background-color: #EB2F2D;
}

@media (max-width: 1250px) {
  .reserve {
    height: auto;
    min-height: 400px;
    padding-bottom: 25px;
  }
  .reserve__content {
    margin-left: 25px;
    padding-top: 50px;
    height: auto;
    min-height: 400px;
  }
  .reserve__content p {
    width: 90%;
  }
}

@media (max-width: 1000px) {
  .reservation-button {
    margin-top: 15px;
    position: absolute;
    top: 145px;
    width: 220px;
    background-size: 40px;
    height: 60px;
    right: -160px;
  }
  .reservation-button.is-showed {
    right: 0px;
  }
  .reservation-button span {
    margin-left: 50px;
  }
  body {
    overflow-x: hidden;
  }
}

.footer-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 10px solid #EB2F2D;
  margin-bottom: 25px;
  padding-top: 25px;
}

.footer-nav li {
  padding: 15px 10px;
  border-bottom: 1px solid #fff;
  transition: all 0.3s;
}

.footer-nav li:hover {
  color: #EB2F2D;
  border-color: #EB2F2D;
}

@media (max-width: 768px) {
  .footer-nav {
    flex-direction: column;
    margin-bottom: 10px;
    padding-top: 10px;
  }
  .footer-nav li {
    padding: 7px 10px;
  }
}

.baner-top {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 500px;
}

.contact-container {
  display: flex;
  justify-content: space-around;
  width: 900px;
  margin: 0 auto;
}

.contact-container__right {
  width: 400px;
  display: flex;
  flex-direction: column;
  font-size: 21px;
  padding-left: 30px;
}

.contact-container__right h2 {
  font-size: 26px;
  font-weight: bold;
}

.contact-container__right p {
  margin: 15px 0px;
}

.contact-container span {
  font-weight: bold;
}

.to-contact {
  margin: 5px 0px;
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 30px;
  background-size: contain;
}

.reserve-contact {
  width: 200px;
  padding: 12px 0px;
  background-color: #EB2F2D;
  border-radius: 25px;
  color: #fff;
  text-align: center;
  transition: all 0.3s;
  font-size: 18px;
}

.reserve-contact:hover {
  background-color: #d1100e;
}

.form-contact {
  font-family: "Lato-Regular", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 400px;
}

.form-contact input, .form-contact textarea {
  margin: 10px 0px;
  height: 50px;
  border-radius: 30px;
  border: 1px solid #EB2F2D;
  transition: all 0.3s;
  padding: 5px 15px;
  width: 100%;
}

.form-contact input:focus, .form-contact textarea:focus {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  outline: none;
}

.form-contact textarea {
  height: 300px;
  padding: 15px;
  font-family: "Lato-Regular", sans-serif;
}

.map {
  width: 100%;
  height: 540px;
  margin-top: 20px;
}

button {
  border: 1px solid #eb2f2d;
  transition: all .3s;
  border-radius: 30px;
  background-color: #fff;
  width: 125px;
  height: 40px;
  outline: none;
  cursor: pointer;
  margin-left: 275px;
}

button:hover {
  background-color: #d1100e;
  color: #fff;
}

.contact-social {
  display: flex;
  margin-bottom: 15px;
}

.contact-social a {
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: all 0.3s;
}

.contact-social a:hover {
  transform: scale(1.2);
}

@media (max-width: 1100px) {
  .contact-container {
    width: 100%;
    flex-direction: column-reverse;
  }
  .contact-container__right {
    margin-bottom: 25px;
    padding-left: 0px;
  }
  .baner-top {
    height: 40vw;
  }
}

@media (max-width: 768px) {
  .form-contact {
    width: 100%;
  }
  .contact-container__right {
    width: 100%;
  }
  .contact-container__left {
    width: 100%;
  }
}

.offert-box {
  width: 70%;
  margin: 0 auto;
  padding: 15px;
  border-bottom: 1px solid #EB2F2D;
}

.offert-box__header {
  display: flex;
  align-items: center;
}

.offert-box__header h2 {
  font-size: 26px;
  margin-right: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.offert-box__header span {
  font-size: 21px;
  color: #EB2F2D;
}

.offert-box__button-more {
  color: #EB2F2D;
  cursor: pointer;
  margin-bottom: 15px;
  margin-top: 15px;
  font-weight: bold;
}

.offert-box__short-about {
  margin: 20px 0px;
  font-size: 18px;
}

.offert-box__about {
  margin: 20px 0px;
  font-size: 16px;
  display: none;
}

.offert-box__about h3 {
  font-weight: bold;
  margin: 20px 0px;
}

.offert-box__about ul li {
  margin: 10px 0px;
  padding-left: 30px;
  position: relative;
}

.offert-box__about ul li:before {
  content: '';
  position: absolute;
  left: 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(../images/pies.svg);
  width: 20px;
  height: 20px;
}

.offert-box__img {
  width: 70%;
  margin: 0 auto;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.3s;
}

@media (max-width: 1100px) {
  .offert-box__img {
    width: 100%;
  }
}

.offert-box__img.is-active {
  height: 45vw;
}

.offert-box__img img {
  width: 100%;
}

.offert-box__about--price {
  width: 50%;
}

.offert-box__about--price h3 {
  margin: 20px 0px;
  font-weight: bold;
}

.offert-box__about--price .price-more {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #000;
}

.offert-box__about--price .price-more .price-category {
  padding-left: 25px;
}

.offert-box__about--price .price-more__right {
  display: flex;
  justify-content: space-between;
  width: 150px;
}

.offert-box__about--price .price-more__right .price {
  font-weight: bold;
  color: #EB2F2D;
}

.message-read-more {
  position: fixed;
  display: none;
  width: 80%;
  height: 650px;
  padding: 45px;
  z-index: 99999999999999;
  background-color: #fff;
  top: 50%;
  left: 50%;
  margin-left: -40%;
  margin-top: -275px;
  overflow: inherit;
  -webkit-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.75);
}

.message-read-more .offert-box__img {
  height: 960px;
}

.close-read-more {
  height: 50px;
  width: 50px;
  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.75);
  position: relative;
  position: absolute;
  right: -20px;
  top: -20px;
  cursor: pointer;
  overflow: initial;
}

.close-read-more:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 3px;
  right: 0;
  content: "\274c";
  /* use the hex value here... */
  font-size: 30px;
  color: #EB2F2D;
  line-height: 49px;
  text-align: center;
}

.message-read-container {
  overflow-y: scroll;
  height: 100%;
  padding-right: 15px;
}

@media (max-width: 768px) {
  .message-read-more {
    top: 50%;
    padding: 45px 20px;
  }
  .message-read-container .offert-box__img {
    height: 40vw;
  }
  .message-read-container .offert-box__about--price {
    width: 100%;
  }
  .message-read-container .offert-box__header {
    flex-direction: column;
  }
  .message-read-container .offert-box__header span {
    margin-top: 15px;
  }
  .offert-box {
    width: 100%;
  }
  .offert-box__about--price {
    width: 100%;
  }
}

.gallery-list {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 300px;
  margin-bottom: 30px;
}

.gallery-list li {
  cursor: pointer;
  font-size: 21px;
}

.gallery-list .is-active {
  color: #EB2F2D;
}

.gallery-container {
  width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.gallery-container__solo {
  height: 400px;
  margin-bottom: 30px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
}

.gallery-container__solo:hover:before {
  transform: scale(1.2);
}

.gallery-container__solo:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  transition: inherit;
}

.gallery-container-single {
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.gallery-header {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  font-size: 40px;
}

.gallery-single {
  width: 300px;
  height: 200px;
  margin: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.gallery-single img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-single a {
  width: 100%;
  height: 100%;
  float: left;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
}

@media (max-width: 1100px) {
  .gallery-container {
    width: 100%;
    justify-content: center;
  }
  .gallery-container__solo {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: 30vw;
  }
}

@media (max-width: 768px) {
  .gallery-header {
    font-size: 24px;
  }
}

.blog-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1300px;
  margin: 0 auto;
}

.blog-box {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 400px;
  margin: 25px 0px;
}

.blog-box .date {
  color: #dedfe0;
}

.blog-box h2 {
  margin: 25px 0px;
  font-size: 24px;
  font-weight: bold;
}

.blog-box .blod-short-desc {
  font-size: 21px;
  color: #000;
}

.blog-box a {
  padding: 5px 10px;
  border: 1px solid #EB2F2D;
  transition: all 0.3s;
  border-radius: 15px;
  position: absolute;
  bottom: 5px;
  left: 0px;
}

.blog-box a:hover {
  color: #fff;
  background-color: #EB2F2D;
}

.blog-box__left {
  width: 600px;
  position: relative;
}

.blog-box__right {
  width: 600px;
}

.blog-box__right img {
  width: 100%;
  height: 100%;
}

.blog-box:nth-child(2n+2) {
  flex-direction: row-reverse;
}

@media (max-width: 1200px) {
  .blog-container {
    width: 100%;
  }
  .blog-box {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
  .blog-box a {
    position: relative;
    bottom: auto;
    top: auto;
  }
  .blog-box__right {
    margin-top: 15px;
  }
  .blog-box:nth-child(2) {
    flex-direction: column;
  }
  .blog-short-desc {
    margin-bottom: 15px;
  }
}

@media (max-width: 1200px) {
  .blog-box__left {
    width: 100%;
  }
  .blog-box__right {
    width: 100%;
  }
}

.single-blog h2 span {
  color: #EB2F2D;
}

.single-blog-container img {
  margin: 25px 0px;
  width: auto;
  height: 100%;
}

.single-blog-container ul {
  line-height: 1.5;
  margin-left: 30px;
}

.single-blog-container ul li {
  list-style: disc;
}

.other-articles {
  align-items: flex-start;
  margin-bottom: 15px;
}

.other-articles h3 {
  font-size: 23px;
  font-weight: bold;
  margin: 35px 0px 20px 0px;
}

.other-articles__list {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.other-articles__single {
  width: 350px;
}

.other-articles__single--photo {
  width: 350px;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.other-articles__single--name {
  background-color: #f4f2f2;
  height: 70px;
  display: flex;
  align-items: center;
  width: 350px;
}

.other-articles__single--name h2 {
  padding-left: 15px;
}

@media (max-width: 768px) {
  .other-articles__list {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .other-articles__list--name {
    width: 350px;
  }
  .other-articles__single {
    margin-bottom: 30px;
  }
}

.reservation-box {
  margin-bottom: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.reservation-box__single {
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
  width: 100%;
}

.reservation-box__single h2 {
  margin-bottom: 15px;
  font-size: 21px;
  font-family: "Lato-Regular", sans-serif;
}

.reservation-box textarea {
  height: 130px;
}

.reservation-box .nice-select {
  border-color: #EB2F2D;
  border-radius: 30px;
}

.reservation-box .list {
  max-height: 360px;
  overflow-y: scroll !important;
}

.read-more {
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
}

.read-more h2 {
  text-align: center;
  font-size: 19px;
  font-weight: bold;
}

.read-more p {
  font-size: 15px;
  text-align: center;
  margin: 10px auto;
  line-height: 1.5em;
  padding: 0 15px;
}
