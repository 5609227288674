.header-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    box-shadow: $small-box-shadow;
    transition: all 0.3s;
    width: 100%;
    position: fixed;
    z-index: 999;
    background-color: #fff;
    top: 0px;
}

.header-menu-active {
    height: 80px;
}

.main-nav {
    margin-right: 45px;
    ul {
        display: flex;
        li {
            padding: 15px 10px;

            a {
                transition: all 0.3s;

                &:hover {
                    color: $primary-color;
                }
            }
        }
    }

    &.is-active {
        top: 80px;
    }
}

.active-link {
    color: $primary-color;
    border-bottom: 1px solid $primary-color;
}

@media(max-width: 1000px) {
    .main-nav {
        a {
            font-size: 12px;
        }
        ul {
            padding-bottom: 20px;
        }
    }
}

@media(max-width: 768px) {
    .main-nav {
        display: none;
        position: absolute;
        // height: 220px;
        height: auto;
        width: 100%;
        top: 100px;
        border-bottom: 1px solid #000;
        background-color: #fff;

        ul {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            li{
                height: 34px;

                a {
                    font-size: 14px;
                }
            }
        }
    }
}


// logo

.logo {
    width: 250px;
    height: 177px;
    // border: 2px solid $primary-color;
    // background-image: url(../images/logo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 20px;
}

@media(max-width: 1350px) {
    .logo {
        margin-left: 15px;
    }
}


// fast call 

.fast-contact {
    display: flex;
    margin-left: 15px;

    &__call {
        display: flex;
        margin-right: 15px;
     

        &--logo {
            width: 40px;
            height: 40px;
            // border: 2px solid $primary-color;
            background-size: contain;
            background-position: center;
            margin: 0px 10px;
        }

        &--text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}

@media(max-width: 1350px) {
    .fast-contact {
        display: none;
    }
}

// reservation button   

.reservation-button {
    position: absolute;
    top: 100px;
    right: 0px;
    width: 210px;
    height: 70px;
    background-color: $primary-color;
    color: #fff;
    border: 1px solid $primary-color;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    background-repeat: no-repeat;
    background-position: 10px;
    background-size: 20%;
    // background-size: contain;
    padding-left: 55px;
    background-image: url(../images/calendar.svg);

    &:hover {
        background-color: #d1100e;
        color: #fff;
    }

    &.is-active {
        top: 300px;
    }
}

.reservation-button-active {
    top: 80px;
}

.reservation-button-mobile {
    top: 310px !important;
}

.language {
    position: absolute;
    top: 32%;
    right: 15px;
    padding-top: 5px;

    &:hover {
        .language__en {
            display: block !important;
        }

        .language__pl {
            display: block !important;
        }
    }

    &__pl {
        width: 30px;
        height: 20px;

        margin-bottom: 5px;
        display: block;
        background-image: url(../images/pl.png);
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__en {
        width: 30px;
        height: 20px;
        display: block;
        // display: none;
        background-image: url(../images/en.png);
        background-size: cover;
        background-repeat: no-repeat;

    }

    &.is-active {
        top: 29%;
    }
}

.reservation-mobile {
    display: none;
}

.reservation-desktop {
    display: flex;
}


@media (max-width: 900px) {
    .logo {
        width: 80px;
        height: 70px;
        top: 0px;
        position: relative;
        margin-left: 0px;
    }
    .header-main-name {
        margin-left: 0px;
    }
}


@media (max-width: 768px) {
    .language {
        right: 80px;
    }

    

    .reservation-mobile {
        // display: none;
   
    }

    .reservation-desktop {
        // display: none;
    }

    .reservation-button {
        background-size: 50px;
        padding-left: 0px;

        span {
            font-size: 16px;
        }
    }
}