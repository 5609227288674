.offer {
    margin-top: 50px;
    padding: 30px 0px;
    background-color: #f3f3f3;
}

.swiper-offert {
    margin-top: 50px;
    padding-bottom: 40px;
}


.swiper-slide-offert {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f3f3f3;

    &__photo {
        width: 270px;
        height: 270px;
        margin: 10px 0px;
        border-radius: 250px;
        -webkit-box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.75);
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__header {
        margin: 15px 0px;
        font-size: 26px;
    }

    &__icon {
        width: 50px;
        height: 20px;
        background-image: url(../images/pies.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__text {
        margin: 15px 0px;
        padding: 0px 20px;

        p {
            margin-bottom: 20px;
            min-height: 80px;
        }

        a {
            padding: 5px 10px;
            border: 1px solid $primary-color;
            transition: all 0.3s;
            border-radius: 15px;

            &:hover {
                color: #fff;
                background-color: $primary-color;
            }
        }
    }
}

.offert-container {
    margin-bottom: 25px;
}