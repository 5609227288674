.gallery-list {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 300px;
    margin-bottom: 30px;

    li {
        cursor: pointer;
        font-size: 21px;
    }

    .is-active {
        color: $primary-color;
    }
}

.gallery-container {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // flex-wrap: wrap;

    &__solo {
        height: 400px;
        margin-bottom: 30px;
        position: relative;
        cursor: pointer;
        transition: all 0.3s;
        background-position: center center;
        background-size: cover;
        overflow: hidden;

        &:hover {

            &:before {
                transform: scale(1.2);
            }
        }

        &:before { 
            content:"";
            position:absolute; top:0;right:0;bottom:0;left:0;
            background:inherit;
            transition:inherit;
        }
    }
}

.gallery-container-single {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.gallery-header {
    color: #fff;
    position: absolute;
    top: 50%;  
    left: 50%; 
    transform: translate(-50%, -50%); 
    text-transform: uppercase;
    font-size: 40px;
}

.gallery-single {
    width: 300px;
    height: 200px;
    margin: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    a {
        width: 100%;
        height: 100%;
        float: left;
        position: absolute;
        top:0px;
        left: 0px;
        z-index: 2;
    }
}

@media (max-width: 1100px) {
    .gallery-container {
        width: 100%;
        justify-content: center;
        
        &__solo {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            height: 30vw;
        }
    }
}

@media (max-width: 768px) {
    .gallery-header {
        font-size: 24px;
    }
}