.read-more {
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 15px;
    
    h2 {
        text-align: center;
        font-size: 19px;
        font-weight: bold;
    }

    p {
        font-size: 15px;
        text-align: center;
        margin: 10px auto;
        line-height: 1.5em;
        padding: 0 15px;
    }
}