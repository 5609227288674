.reservation-box {
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &__single {
        display: flex;
        flex-direction: column;
        margin: 15px 0px;
        width: 100%;

        h2 {
            margin-bottom: 15px;
            font-size: 21px;
            font-family: $main-font-family;
        }
    }

    textarea {
        height: 130px;
    }

    .nice-select {
        border-color: $primary-color;
        border-radius: 30px;
    }

    .list {
        max-height: 360px;
        overflow-y: scroll !important;
    }
}
