

.footer-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 10px solid $primary-color;

    margin-bottom: 25px;
    padding-top: 25px;

    li {
        padding: 15px 10px;
        border-bottom: 1px solid #fff;
        transition: all 0.3s;

        &:hover {
            color: $primary-color;
            border-color: $primary-color;
        }
    }
}

@media (max-width: 768px) {
    .footer-nav {
        flex-direction: column;
        margin-bottom: 10px;
        padding-top: 10px;

        li {
            padding: 7px 10px;
        }
    }
}